<template>
  <div>
    <a-card style="margin-bottom: 24px" :bordered="false">
      <a-row type="flex" align="middle" :gutter="[20, 0]">
        <a-col :lg="6" :md="8" :xs="24" class="option-wrapper">
          <span>地区查询：</span>
          <a-cascader
            :show-search="{ filter }"
            style="flex: 1"
            :options="options"
            :load-data="loadData"
            placeholder="请选择地区"
            :field-names="{ label: 'name', value: 'id', children: 'children' }"
            change-on-select
            @change="onAreachange"
          />
        </a-col>

        <a-col :lg="6" :md="8" :xs="24" class="option-wrapper">
          <span>日期查询：</span>
          <a-range-picker style="flex: 1" valueFormat="YYYY-MM-DD" allowClear @change="onDatechange" />
        </a-col>
        <a-col :lg="6" :md="8" :xs="24" class="option-wrapper">
          <span>用户查询：</span>
          <a-input
            v-model="query.userName"
            allowClear
            placeholder="输入用户账号或姓名"
            style="flex: 1"
            @change="initData"
          />
        </a-col>
      </a-row>
    </a-card>

    <a-card title="用户列表" v-if="$auth('assessment_equipment_user_list')" :bodyStyle="{ padding: 0 }">
      <main-table ref="table"></main-table>
    </a-card>
  </div>
</template>

<script>
import * as toolApi from '@/api/tool'
import MainTable from './components/Table'

export default {
  name: 'ToolList',
  components: {
    MainTable,
  },
  data() {
    return {
      query: {
        cityId: undefined,
        countyId: undefined,
        pageIndex: undefined,
        pageSize: undefined,
        provinceId: undefined,
        registrationEndTime: undefined,
        registrationStartTime: undefined,
        userName: undefined,
      },
      options: [],
    }
  },
  mounted() {
    // 获取地区数据
    toolApi.getArea({ parentId: 142 }).then((res) => {
      this.options = res.data.map((item) => {
        item.isLeaf = false
        return item
      })
    })
    this.initData()
  },
  methods: {
    // 更新表格数据
    initData() {
      this.$refs.table.initData(this.query)
    },
    // 日期筛选
    onDatechange(date) {
      this.query.registrationStartTime = date[0]
      this.query.registrationEndTime = date[1]
      this.initData()
    },
    // 用户名称筛选
    onAreachange(value) {
      this.query.provinceId = value[0]
      this.query.cityId = value[1]
      this.query.countyId = value[2]
      this.initData()
    },
    // 请求省市区数据
    loadData(selectedOptions) {
      console.log(selectedOptions)
      const targetOption = selectedOptions[selectedOptions.length - 1]
      targetOption.loading = true
      if (selectedOptions.length < 2) {
        toolApi.getArea({ parentId: targetOption.id }).then((res) => {
          targetOption.loading = false
          if (res.data.length !== 0) {
            targetOption.children = res.data.map((item) => {
              item.isLeaf = false
              return item
            })
          }
          this.options = [...this.options]
        })
      } else {
        toolApi.getArea({ parentId: targetOption.id }).then((res) => {
          targetOption.loading = false
          if (res.data.length !== 0) {
            targetOption.children = res.data
          }
          this.options = [...this.options]
        })
      }
    },
  },
}
</script>

<style lang="less" scoped></style>

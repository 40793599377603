<template>
  <a-table rowKey="id" :data-source="list" :pagination="pagination" :loading="listLoading" @change="handleTableChange">
    <a-table-column
      key="childName"
      title="孩子昵称"
      data-index="childName"
      align="center"
      :ellipsis="true"
      width="9%"
    ></a-table-column>
    <a-table-column
      key="cellphone"
      title="用户账号"
      data-index="cellphone"
      align="center"
      :ellipsis="true"
      width="8%"
    ></a-table-column>
    <a-table-column key="sex" title="性别" data-index="sex" align="center" :ellipsis="true" width="7%">
      <template slot-scope="sex">
        <span>
          {{ { MALE: '男', FEMALE: '女' }[sex] }}
        </span>
      </template>
    </a-table-column>
    <a-table-column key="age" title="年龄" data-index="age" align="center" :ellipsis="true" width="7%">
      <template slot-scope="age">
        <span>
          {{ age ? age : '—' }}
        </span>
      </template>
    </a-table-column>
    <a-table-column
      key="isBuyEquipment"
      title="是否购买嗨小保"
      data-index="isBuyEquipment"
      align="center"
      :ellipsis="true"
      width="11%"
    >
      <template slot-scope="isBuyEquipment">
        <span>
          {{ isBuyEquipment ? '是' : '—' }}
        </span>
      </template>
    </a-table-column>
    <a-table-column
      key="createTimestamp"
      title="注册日期"
      data-index="createTimestamp"
      align="center"
      :ellipsis="true"
      width="9%"
    ></a-table-column>
    <a-table-column
      key="assessmentFinishTime"
      title="完成评估时间"
      data-index="assessmentFinishTime"
      align="center"
      :ellipsis="true"
      width="9%"
    >
      <template slot-scope="assessmentFinishTime">
        <span>
          {{ assessmentFinishTime ? assessmentFinishTime : '—' }}
        </span>
      </template>
    </a-table-column>
    <a-table-column
      key="relationship"
      title="家长角色"
      data-index="relationship"
      align="center"
      :ellipsis="true"
      width="8%"
    >
      <template slot-scope="relationship">
        <span>
          {{
            {
              FATHER: '爸爸',
              MOTHER: '妈妈',
              GRANDFATHER: '爷爷',
              GRANDMOTHER: '奶奶',
              GRANDMA: '姥姥',
              GRANDPA: '姥爷',
              OTHER: '其他',
            }[relationship]
          }}
        </span>
      </template>
    </a-table-column>
    <a-table-column
      key="area"
      title="地区"
      data-index="area"
      align="center"
      :ellipsis="true"
      width="14%"
    ></a-table-column>

    <a-table-column key="actions" title="操作" align="center" :ellipsis="true" width="8%">
      <template slot-scope="record">
        <a-button
          v-if="$auth('assessment_equipment_select_detail')"
          type="link"
          size="small"
          @click="handleDetail(record)"
        >
          <a-icon type="eye" />详情</a-button
        >
      </template>
    </a-table-column>
  </a-table>
</template>

<script>
import * as toolApi from '@/api/tool'

export default {
  data: function () {
    return {
      query: {},
      pagination: {},
      listLoading: false,
      list: [],
    }
  },
  activated() {
    const { ready, getData } = this
    if (ready) {
      getData()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.ready = true
    })
  },
  methods: {
    initData(query = {}) {
      const { initPagination, getData } = this
      this.query = query
      this.pagination = initPagination()
      return getData()
    },
    getData() {
      const {
        pagination: { current: pageIndex, pageSize },
        query,
      } = this

      this.listLoading = true

      return toolApi
        .getList({ ...query, pageIndex, pageSize })
        .then((res) => {
          const { list, totalItems: total } = res.data

          this.list = list
          this.pagination.total = total
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    initPagination() {
      return {
        current: 1,
        pageSize: 10,
        showTotal: (total) => {
          const h = this.$createElement
          const {
            pagination: { pageSize },
          } = this

          return h('div', {}, [
            h('span', '共 '),
            h('span', { class: 'primary-color' }, pageSize ? Math.ceil(total / pageSize) : Math.ceil(total / 10)),
            h('span', ' 页'),
            h('span', ' / '),
            h('span', { class: 'primary-color' }, total),
            h('span', ' 条数据'),
          ])
        },
        showSizeChanger: true,
        total: 0,
        pageSizeOptions: ['10', '20', '50', '100'],
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const { getData } = this

      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize

      getData()
    },
    handleDetail(record) {
      this.$router.push({ path: '/tool/detail/' + record.id })
    },
  },
}
</script>

<style lang="less" scoped>
.desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-divider,
.ant-divider-vertical {
  margin: 0 16px;
}
/deep/ .ant-table-pagination.ant-pagination {
  margin: 24px;
}
@media (max-width: 1919px) {
  /deep/ .ant-btn {
    font-size: 12px;
  }
}
</style>
